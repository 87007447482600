import React from 'react'

import SEO from '~components/seo'
import Home from '~containers/Home'

export const Head = () => <SEO title="Home" />

const IndexPage = () => <Home />

export default IndexPage
