const DATA = [
  {
    title: 'Клімат України',
    descr: [
      'Клімат - це багаторічний режим погоди в даній місцевості, зумовлений географічними умовами',
      "Клімат має величезне значення, оскільки під впливом основних факторів кліматоутворення - тепла, вологи, тривалості світлового дня - формується відповідна рослинність. З рослинами тісно пов'язані тварини. Господарська діяльність людини також обумовлюється кліматичними факторами. В більш сприятливих кліматичних умовах вища щільність населення, концентрація промисловості.",
    ],
  },
  {
    title: 'Основні метеорологічні елементи',
    descr: [
      'Температура повітря, вологість, опади, тиск, рух повітря та деякі інші атмосферні процеси і явища являють собою метеорологічні елементи, які безперервно змінюються, утворюючи в кожний окремий момент на кожній території різні комбінації, що характеризують фізичний стан атмосфери. Фізичний стан атмосфери в певній місцевості в даний момент або за невеликий відрізок часу, який характеризується сукупністю значень метеорологічних елементів, називається погодою.',
    ],
  },
  {
    title: 'Індекс якості повітря',
    descr: [
      'Індекс якості повітря (Air quality index, AQI) - значення, яке використовується урядовими установами, щоб донести до громадськості рівень забруднення повітря у певний час',
      "Якщо індекс збільшиться, значна частина населення зіткнеться з серйозними наслідками для здоров'я. У різних країнах є свої показники якості повітря відповідно до різних національних стандартів.",
      'В Україні рівень якості повітря вимірюється за US-EPA стандартами за кількістю PM2.5 часточок у повітрі.',
    ],
    link: {
      title: 'Дізнатися більше про індекс якості повітря у Львові можна',
      url: '/aqi',
    },
  },
  {
    title: 'Визначення і використання',
    descr: [
      "Розрахунок індексу якості повітря вимагає середніх значень концентрації забруднювальних речовин за вказаний період, які отримані з моніторингу довкілля або моделювання атмосферної дисперсії. Разом взяті концентрація і час є дозою забруднювальних речовин в повітрі. Можливі наслідки для здоров'я відповідно до впливу дози встановлюються епідеміологічними дослідженнями.",
      "Індекс якості повітря зазвичай об'єднують в діапазони. Кожному діапазону присвоюється ідентифікатор, код кольору і рекомендації охорони здоров'я.",
    ],
  },
  {
    title: 'Природний радіаційний фон',
    descr: [
      'Гамма-промені — це електромагнітне випромінювання найвищої енергії з довжиною хвилі меншою за 1 ангстрем. Утворюється в реакціях за участю атомних ядер і елементарних частинок у процесах розпаду, синтезу, анігіляції, під час гальмування заряджених частинок великої енергії.',
      'Позначаються грецькою літерою γ.',
      'Природний радіаційний фон  базується на трьох складових. Це космічна радіація (протони, альфа-частинки, гамма-промені), випромінювання природних радіоактивних речовин, що присутні у ґрунті, і випромінювання тих радіоактивних речовин (також природних), що потрапляють до нас в організм із повітрям, їжею, водою.',
      'Крім цього на величину природного радіоактивного фону впливає і діяльність людини. Наприклад, при спалюванні вугілля в теплових електростанці­ях у довкілля потрапляють від­ходи - зола, а вона містить радіоактивні елементи - торій, уран, радій, що колись були сконцентровані рослинами, з яких вугілля утворилося. Іноді фон біля таких станцій вищий, ніж біля атомних. Певне забруднення викликала аварія на Чорнобильській атомній станції.',
      'Природний радіаційний фон не є постійним і коливається від 4 до 20 мкР/год для житлових помешкань і до 50 мкР/год для природного середовища',
    ],
    link: {
      title: 'Дізнатися більше про Природний радіаційний фон у Львові можна',
      url: 'https://www.saveecobot.com/radiation/lvivska-oblast',
      isExternal: true,
    },
  },
]
export default DATA
