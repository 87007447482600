import { Link } from 'gatsby'
import React from 'react'
import { Container } from 'react-bootstrap'
import DATA from './constants'

import * as s from './Home.module.scss'

const Home = () => {
  return (
    <Container className={s.home}>
      <h1 className={s.home_title}>
        Система моніторингу впливу кліматичних умов на здоров&apos;я населення
      </h1>

      <div className={s.home_content}>
        {DATA.map(({ title, descr, link }) => (
          <div key={title} className={s.home_block}>
            <h2>{title}</h2>

            {descr.map((p, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`p${i}`}>{p}</p>
            ))}

            {link && (
              <>
                {link.title}{' '}
                {React.createElement(
                  link.isExternal ? 'a' : Link,
                  {
                    ...(link.isExternal
                      ? {
                          href: link.url,
                          target: '_blank',
                          rel: 'noreferrer noopener',
                        }
                      : { to: link.url }),
                  },
                  'тут'
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Home
